body{
  font-family: 'Nunito Sans', sans-serif;
  padding: 0 !important;
}
.container{
  max-width: 1366px !important;
  padding: 0 !important;
  @media (max-width: 1100px){
    padding: 0 !important;
    &>div{
      padding-left: 25px;
      padding-right: 25px;
      box-sizing: border-box;
    }
  }
}
p.error{
  margin: 0;
  position: absolute;
  bottom: 5px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  color: red;
}
.logo{
  width: 173px;
  height: 54px;
  @media (max-width: 1100px){
    width: 140px;
    height: auto;
  }
  @media (max-width: 900px){
    display: none;
  }
  &.mobile{
    display: none;

    @media (max-width: 900px){
      display: block;
      margin-top: 20px;
      width: 132px;
      height: 42px;
      margin-left: 10px;
    }
    .static-page &{
      margin-left: 15px;
    }
  }
}
.btn.MuiButton-contained{
  border-radius: 5px;
  display: block;
  background-image: linear-gradient(to top, #ff9d00, #ffc600);
  width: 192px;
  height: 48px;
  padding-left: 32px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: 1.6px;
  text-align: left;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  cursor: pointer;
  box-shadow: none !important;
  &.next{
    background:url("/img/arrow-right.png") no-repeat calc(100% - 15px) center,  linear-gradient(to top, #ff9d00, #ffc600) ;
    background-size:14px, auto;
  }
  &.back{
    background:url("/img/arrow-left.png") no-repeat 15px center,  linear-gradient(to top, #ff9d00, #ffc600) ;
    background-size:14px, auto;
    padding-left: 45px;
  }
  @media (max-width: 550px){
    padding-left: 25px;
  }
  &.fixed{
    display: block;
    position: fixed;
    bottom: 20%;
    right: 0;
    @media (max-width: 900px){
      display: none !important;
    }
  }
}
h2{
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 0;
  @media (max-width: 1100px){
    font-size: 35px;
  }
  @media (max-width: 900px){
    font-size: 30px;
    text-align: left;
  }
}
.header{
  @media (max-width: 1150px){
    padding: 0 10px;
  }
  @media (max-width: 900px){
    position: absolute;
    top: 0;
    z-index: 5;
    width: 100%;
  }
  .static-page &{
    @media (max-width: 900px){
      background: #000;
      margin-left: -15px;
    }
  }
  &__menu{
    @media (max-width: 1100px){
      max-width: 33% !important;
      flex-basis: 33% !important;
    }
    @media (max-width: 900px){
      justify-content: flex-end;
    }
    &-icon{
      display: none;
      @media (max-width: 900px){
        display: block;
        svg{
          fill: #fff;
        }
      }
    }
  }
  &__switch{
    width: 455px;
    height: 42px;
    border-radius: 2px;
    background-color: #e9ecef;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 1000px){
      width: 430px;
      margin: 0 auto;
    }

    @media (max-width: 900px){
      background:#fff;
      border-radius: 23px;
      height: 46px;
      padding: 3px;
      margin: 0;
      box-sizing: border-box;
    }
    &-title{
      display: block;
      height: 42px;
      width: 55px;
      background-image: linear-gradient(to bottom, #ff9d00, #ffc600);
      font-family: 'Nunito Sans', sans-serif;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 42px;
      letter-spacing: normal;
      text-align: center;
      color: #020202;
      text-transform: uppercase;
    }
    p{
      font-family: 'Montserrat',sans-serif;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left; margin: 0;
      text-transform: uppercase;
      @media (max-width: 1000px){
        font-size: 12px;
      }
      @media (max-width: 900px){
        font-family: Montserrat, sans-serif;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.65px;
        text-align: center;
        color: #000;
      }

    }
    p.active{
      color: #000;
      padding: 0 10px 0 15px;
      @media (max-width: 900px){
        width: 50%;
        height: 40px;
        border-radius: 20px;
        display: flex;
        background-image: linear-gradient(to left, #ff9d00, #ffc600);
        padding: 9px 25px;
        box-sizing: border-box;
        text-align: center;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.65px;
      }
      @media (max-width: 400px){
        padding: 9px 10px;
      }
    }
    p.no-active{
      color: rgba(0, 0, 0, 0.5);
      padding-left: 10px;
    }
    a{
      @media (max-width: 900px){
        font-family: 'Montserrat',sans-serif;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        letter-spacing: 0.65px;
        width: 50%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 9px 25px;
        border-radius: 20px;
        box-sizing: border-box;
        text-transform: uppercase;
        &:hover{
          background-image: linear-gradient(to left, #ff9d00, #ffc600);
          color: #000;
        }
        @media (max-width: 400px){
          padding: 9px 10px;
        }
      }
    }
  }
}

.menu__box{
  display: flex;
  margin: 0 50px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1100px){
    margin: 0 10px;
  }
  @media (max-width: 900px){
    &>div{
      width: 150px !important;
      left: auto !important;
      right: 25px;
      padding: 15px;
    }
  }
  .menu__link{
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    @media (max-width: 1100px){
      font-size: 12px;
    }
    @media (max-width: 900px){
      font-size: 14px;
      width: 100%;
      display: block;
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
.box-switch{
  @media (max-width: 900px){
    display: none;
  }
  &-mobile{
    display: none;
    @media (max-width: 900px){
      display: flex;
      width: calc(100% + 50px) !important;
      padding: 10px 25px;
      order: -1;
      background: #e9ecef;
      justify-content: center;
      height: 67px;
      margin-left: -25px !important;
      margin-right: -25px !important;
    }
    @media (max-width: 400px){
      padding: 10px;
    }
    .static-page &{
      @media (max-width: 900px){
        display: flex;
        width: 100% !important;
        padding: 10px 25px;
        order: -1;
        background: #e9ecef;
        height: 67px;
        margin: 0 !important;
      }
    }
  }
}
.banner{
  &__box{
    position: relative;
    width: 100%;

    @media  (max-width: 1890px){
      width: 100%;
      margin: 0;
      min-height: 668px;
      background: url("/img/banner.jpg") no-repeat right center;
      background-size: cover;
      img{
        display: none;
      }
    }
    @media (max-width: 1100px){
      background: url("/img/banner.jpg") no-repeat 70% center;
    }
    @media (max-width: 900px){
      margin-top: 65px;
    }
    @media  (max-width: 900px){
      background: linear-gradient(to top, rgba(18, 16 ,11, 0.8 ), rgba(255, 255, 255, 0.1)), url("/img/banner.jpg") no-repeat 72% top;
      background-size: cover;
      padding-bottom: 40px;
    }
    img{
      width: 100%;
      min-height: 620px;
    }
  }
    &__text{
      max-width: 1113px;
      width: 100%;
      position: absolute;
      top: 15%;
      left: calc((100vw - 1113px)/2);

      @media  (max-width: 1890px){
       position: initial;
        margin:0 auto;
        padding-top: 100px;
      }
      @media  (max-width: 1150px){
        margin: 0 0 0 10px;
        width: auto;
      }
      @media  (max-width: 1100px){
        margin: 0 0 0 25px;
      }
      @media (max-width: 900px){
        padding-top: 250px;
        margin: 0 0 0 35px;
      }
      h2{
        font-size: 60px;
        text-align: left;
        color: #fff;
        max-width: 535px;
        margin: 0;
      }
      p{
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        margin: 30px 0 40px;
        max-width: 475px;
      }
      @media  (max-width: 900px){
        h2{font-size: 30px;}
        p{font-size: 18px; margin: 15px 0; line-height: 1.47;}

      }
  }
}

.title__sliderForm{
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 2.6px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
}
.sliderLogo__box{
  max-width: 1113px;
  margin: 85px auto 80px;
  .title__sliderForm{
    margin:0 auto 50px;
    max-width: 1020px;
    @media (max-width: 900px){
      font-size: 14px;
    }
  }
  .slick-slider{
    max-width: 1000px;
    margin: 0 auto;
    min-height: 45px;
    @media (max-width: 1100px){
      width: 91%;
    }
  }
  .slick-slide{
    @media(max-width: 480px) {
      &>div{
        text-align: center;
      }
    }
    .slide{
      height: 38px;
      margin-right: 45px;
      cursor: pointer;
      text-indent: -99999px;
      @media (max-width: 1100px){
        margin-right: 35px;
      }
      @media(max-width: 480px) {
        margin-right: 0;
      }
      &.slide1{
        background: url("../src/img/sliderLogo/logo1.svg") no-repeat;
        background-size: cover;
        width: 137px !important;
        height: 38px;
      }
      &.slide2{
        background: url("../src/img/sliderLogo/logo2.svg") no-repeat;
        background-size: cover;
        width: 255px !important;
      }
      &.slide3{
        background: url("../src/img/sliderLogo/logo3.svg") no-repeat;
        background-size: cover;
        width: 112px !important;
      }
      &.slide4{
        background: url("../src/img/sliderLogo/logo4.svg") no-repeat;
        background-size: cover;
        width: 290px !important;
      }
      &.slide5{
        background: url("../src/img/sliderLogo/logo5.svg") no-repeat;
        background-size: cover;
        width: 122px !important;
      }
    }

    &:hover{
      .slide{
        &.slide1{
          background: url("../src/img/sliderLogo/logo1_color.svg") no-repeat;
          background-size: cover;
          height: 40px;
        }
        &.slide2{
          background: url("../src/img/sliderLogo/logo2_color.svg") no-repeat;
          background-size: cover;
        }
        &.slide3{
          background: url("../src/img/sliderLogo/logo3_color.svg") no-repeat;
          background-size: cover;
          height: 39px;
        }
        &.slide4{
          background: url("../src/img/sliderLogo/logo4_color.svg") no-repeat;
          background-size: cover;
        }
        &.slide5{
          background: url("../src/img/sliderLogo/logo5_color.svg") no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .slick-prev, .slick-next{
    width: 9px;
    height: 15px;
  }
  .slick-prev{
    left: -55px;
    @media (max-width: 1115px){
      left: -25px;
    }
  }
  .slick-next{
    right: -55px;
    @media (max-width: 1115px){
      right: -25px;
    }
  }
  .slick-prev:before, .slick-next:before{
    background: #e9ecef;
    color: #e9ecef;
    font-size: 0;
    content: '';
    width: 10px;
    height: 3px;
    display: block;
    top: 0;
    left:0;
    position: absolute;
  }
  .slick-prev:after, .slick-next:after{
    background: #e9ecef;
    color: #e9ecef;
    font-size: 0;
    content: '';
    display: block;
    width: 10px;
    height: 3px;
    top: 5px;
    left: 0;
    position: absolute;
  }
  .slick-prev:before, .slick-next:after{
    transform: rotate(-45deg);
  }
  .slick-next:before,.slick-prev:after{
    transform: rotate(45deg);
  }
}
.why{
  &__box{
    max-width: 1113px;
    width: 100%;
    margin: 0 auto 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    background: #e9ecef;
    .btn{
      display: none;
      position: absolute;
      bottom:30px;
      right: -128px;
      @media (max-width: 1440px){
        right: 0;
      }
    }
    @media (max-width: 1200px){
      img{
        width: 50%;
        max-width: 539px;
      }
    }
    @media (max-width: 1100px){
      padding-left: 0 !important;
      padding-right: 0 !important;

    }
    @media (max-width: 900px){
      img{
        width: 100%;
        max-width: 100%;
        display: none;
      }
      .btn{
        display: block;
        bottom: 0;
        width: 300px;
        right: calc((100% - 310px)/2);
        text-align: center;
        padding: 10px;
      }
    }
  }
  &__img{
    display: none;
    text-indent: -9999px;
    @media (max-width: 900px){
      display: block;
      height: 450px;
      width: 100%;
      background: url("../src/img/why_bg.jpg") no-repeat center center;
      background-size: cover;
    }
    @media (max-width: 400px){
      display: block;
      height: 250px;
      width: 100%;
      background: url("../src/img/why_bg.jpg") no-repeat center center;
      background-size: cover;
    }
  }
  &__text{
    background: #e9ecef;
    width: calc(100% - 539px);
    box-sizing: border-box;
    padding: 78px 0 77px 60px;
    h2{
      text-align: left;
      color: #000;
      margin: 0 0 45px;
      span{
        color: #ff9d00;
      }
    }
    p{
      font-family: 'Nunito Sans',sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      max-width: 445px;
      margin: 0;
    }
    @media (max-width: 1200px){
      width: 50%;
      padding: 58px 20px 57px 60px;
      }
    @media (max-width: 900px){
      width: 100%;
      padding: 30px 30px 35px 30px;
      h2{
        font-size: 30px;
        margin-bottom: 20px;
      }
      p{
        font-size: 16px;
        max-width: 100%;
      }
    }
  }
}
.benefits{
  &__box{
    width: 100%;
    min-height: 850px;
    padding: 55px 0 50px;
    background: url("/img/unicorn.png") no-repeat calc((100vw - 1115px)/2 + 810px) calc(100% + 40px),
    url("/img/bg_gradient.png") no-repeat calc((100vw - 1115px)/2 + 360px) 550px;
    text-align: center;
    box-sizing: border-box;
    h2{
      background: url("/img/plus.png") no-repeat left top;
      background-size: 55px;
      padding: 45px 0 0 110px;
      display: inline-block;
      max-width: 890px;
      @media (max-width: 1100px){
        padding: 45px 0 0 80px;
        max-width: 700px;
      }
    }
    @media (max-width: 1115px){
      padding-top: 25px;
      padding-bottom: 40px;
      min-height: 500px;
    }
    @media (max-width: 900px){
      text-align: left;
      padding: 0 25px 20px 30px;
      background: url("/img/unicorn.png") no-repeat 180px calc(100% + 10px), url("/img/ugol.png") no-repeat 100px bottom;
      background-size: auto 255px, auto;
      h2{
        background: url("/img/plus.png") no-repeat right top;
        background-size: 43px;
        padding: 25px 35px 0 0;
        display: inline-block;
        span{
          display: block;
        }
      }
    }
  }
  &__content{
    max-width: 1113px;
    width: 95%;
    margin: 50px auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    img{
      align-self: stretch;
    }
    @media (max-width: 1100px){
      width: 100%;

    }
    @media (max-width: 900px){
      margin: 25px auto;
      &>img{
        display: none;
      }
    }
  }
  &__text{
    width: calc(100% - 419px);
    padding-left: 65px;
    @media (max-width: 1100px){
      width: 50%;
      padding-left: 35px;
    }
    @media (max-width: 950px) {
      ul li{
        margin-bottom: 33px;
      }
    }

    @media (max-width: 900px){
      width: 100%;
      padding-left: 0;
      ul li{
        flex-wrap: wrap;
        margin-bottom: 40px;
      }
      ul li > div:last-child{
        width: 100%;
        margin-top: 20px;
      }
    }
    &-avatar{
      width: 55px;
      height: 55px;
      margin-right: 45px;
      div{
        width: auto;
        height: auto;
        border-radius: 0;
        img{
          height: auto;
          width: auto;
          margin: 0 auto;
        }
      }
      @media (max-width: 1100px){
        width: 35px;
        height: 35px;
        min-width: 35px !important;
        margin-right: 30px;
        img{
          width: 100% !important;
        }
      }
      @media (max-width: 900px){
        width: 52px;
        height: 52px;
        min-width: 52px !important;
      }
    }
    &-typografy{
      font-family: 'Nunito Sans', sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      margin: 0;
      @media (max-width: 1030px){
        font-size: 20px;
      }
    }
    &-bottom{
      font-family: 'Montserrat',sans-serif;
      font-size: 28px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      max-width: 500px;
      position: relative;
      margin: 0;
      @media (max-width: 1100px){
        font-size: 21px;
      }
      @media (max-width: 900px){
        span{
          display: block;
        }
      }
      a{
       margin-left: 15px;
        @media (max-width: 1100px){
          img{
            width: 20px;
          }
        }
        @media (max-width: 900px){
          margin-left: 0;
        }
      }
    }
  }
}
.how{
  &__box{
    max-width: 1113px;
    margin: 40px auto 80px;
    padding-top: 63px;
    @media (max-width: 1100px){
      background: none;
      text-align: center;
    }
    @media (max-width: 900px){
      padding-top: 20px;
    }
    h2{
      background: url("/img/plus.png") no-repeat left top;
      background-size: 63px;
      padding: 65px 0 0 65px;
      display: inline-block;
      @media (max-width: 900px){
        text-align: left;
        background: url("/img/plus.png") no-repeat right top;
        background-size: 43px;
        padding: 43px 43px 0 0;
      }
    }
    h3{
      font-family: 'Montserrat',sans-serif;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      margin:0 auto 30px;
      max-width: 280px;
      @media (max-width: 900px){
        font-size: 22px;
        margin-bottom: 25px;
      }
    }
    p{
      font-family: 'Nunito Sans',sans-serif;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      max-width: 280px;
      margin: 0 auto 30px;
      &:last-child{
        max-width: 310px;
      }
      @media (max-width: 667px){
        max-width: 100%;
        font-size: 16px;
        &:last-child{
          max-width: 100%;
        }
        br{
          display: none;
        }
      }
    }
    img{
      margin: 0 auto;
      max-width: 100%;
      display: block;
      @media (max-width: 900px){
        max-width: 95%;
      }
    }
    svg{
      display: block;
      width: 26px;
      height: 17px;
      margin: 0 auto;
    }
    .slick-slider{
      display: none;
      @media (max-width: 900px) {
        display: block;
      }
    }
    .slick-dots li button:before{
      color: #e9ecef;
      opacity: 1;
      font-size: 8px;
    }
    .slick-dots li.slick-active button:before{
      color: #ff9d00;
      opacity: 1;
    }
  }
  &__grid{
    @media (max-width: 900px) {
      display: none !important;
    }
  }

  &__col-img{
    position: relative;
    max-width: 253px;
    margin: 0 auto 70px;
    span{
      display: flex;
      width: 62px;
      height: 62px;
      background-image: linear-gradient(to bottom, #ff9d00, #ffc600);
      font-family: 'Montserrat',sans-serif;
      font-size: 26px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 5.2px;
      text-align: center;
      color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      bottom: -30px;
      left: 50%;
      margin-left: -31px;
    }
    @media (max-width: 900px) {
      margin: 0 auto 40px;
      img{
        max-width: 198px;
      }
      span{
        width: 50px;
        height: 50px;
        font-size: 20px;
        bottom: -20px;
        margin-left: -25px;
      }
    }
  }
  &__block{
    cursor: pointer;
    max-width: 382px;
    padding: 40px 40px 0 !important;
    @media (max-width: 900px) {
      margin: 0 auto;
      display: block !important;
      padding: 25px 0 0 !important;
    }
    &:hover{
      background-image: linear-gradient(to bottom, #ff9d00, #ffc600);
      border-radius: 10px;
      box-shadow: 13px 22.5px 38px 0 rgba(10, 17, 33, 0.16);
      svg{
        fill: #fff;
        path{
          fill: #fff !important;
        }
      }
      .how__col-img span{
        background: #fff;
        color: #ff9d00;
      }
      @media (max-width: 900px) {
        background: none;
        box-shadow: none;
        .how__col-img span{
          color: #fff;
          background-image: linear-gradient(to bottom, #ff9d00, #ffc600);
        }
      }
    }
  }
}
.services{
  &__content{
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    margin-left: calc((100% - 1115px)/2);
    width: calc(100% - ((100% - 1115px)/2));

    @media (max-width: 1115px){
      width: 100%;
      margin-left: 0;
      box-sizing: border-box;
    }
    @media (max-width: 900px){
      margin-left: 0;
      width: 100%;
    }

  }
  &__box{
    background-color: #e9ecef;
    width: 100%;
    padding: 80px 0;
    display: flex;
    @media (max-width: 1366px){
      width: 100%;
      margin: 0;
      box-sizing: border-box;
    }

    .slick-slider{
      width: calc(92% - 555px);

    }
    .slick-slide{
      height: 520px;
      padding: 0 43px 0 0;
      box-sizing: border-box;
      @media (max-width: 1120px){
       height: 340px;
      }
      @media (max-width: 900px){
        padding: 0 22px;
      }
    }
    .slick-dots li button:before{
      color: #fff;
      opacity: 1;
      font-size: 8px;
    }
    .slick-dots li.slick-active button:before{
      color: #ff9d00;
      opacity: 1;
    }
    @media (max-width: 1100px) and (min-width: 900px){
      padding: 50px 25px !important;
      .slick-slider{
        width: 50%;
      }
      .slick-slide{

        height: 300px;
      }
    }
    @media (max-width: 900px){
      padding-top: 10px;
      h2{
        text-align: left;
      }
      .slick-slider{
        width:100%;
        max-width: 100%;
      }
      .slick-slide{
        height: 350px;
        padding: 20px 30px;
        .services__slide{
          height: 225px;
          min-width: 100%;
        }
        &.slick-center{
          padding: 0 10px;
          .services__slide{
            height: 260px;
          }
        }
      }
    }
  }
  &__text{
    max-width: 455px;
    margin-left: 30px;
    margin-right: 100px;
    h2{
      margin-top: 40px;
      text-align: left;
      @media (max-width: 900px){
        margin: 40px auto 0;
        display: block;
        text-align: left;
      }
    }
    p{
      font-family: 'Montserrat',sans-serif;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: 4.4px;
      text-align: left;
      color: #000;
      margin: 35px 0 30px;
      max-width: 335px;
      text-transform: uppercase;
      display: block;
    }
    @media (max-width: 1100px){
      margin-left: 30px;
      margin-right: 50px;
      width: 35%;
      p{
        font-size: 18px;
      }
    }
    @media (max-width: 900px){
      width: 100%;
      max-width: 100%;
      h2{
        text-align: left;
      }
      p{
        font-size: 14px;
        max-width: 355px;
        margin: 25px 0 30px;
      }
      img{
        display: none;
      }
    }
  }
  &__slide{
    width: 460px !important;
    height: 460px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 13px 22.5px 38px 0 rgba(10, 17, 33, 0.16);
    display: flex !important;
    flex-flow: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img{
      width: 164px;
      height: 164px;
      margin-bottom: 40px;
    }
    p{
      font-family: 'Montserrat',sans-serif;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      max-width: 265px;
    }
    @media (max-width: 1220px){
      width: 430px !important;
      height: 430px;
    }
    @media (max-width: 1120px){
      width: 100% !important;
      min-width: 350px;
      height: 255px;
      img{
        width: 90px;
        height: 90px;
        margin-bottom: 10px;
      }
      p{
        width: 95%;
        font-size: 22px;
      }
    }
    @media (max-width: 900px){

    }
  }
}
.form{
  &__box{
    margin: 70px 0 0;
    padding-bottom: 130px;
    background: url("/img/bg_gradient.png") no-repeat calc((100vw - 1115px)/2 - 600px) calc(100% + 850px);
    @media (max-width: 900px){
      background: none;
      margin-top: 30px;
      padding-bottom: 0;
    }
    h2{
      font-size: 22px;
      line-height: 1.45;
      letter-spacing: 4.4px;
      margin:0 auto 125px;
      max-width: 1050px;

      @media (max-width: 900px){
        font-size: 12px;
        max-width: 90%;
        margin:0 auto 25px;
        text-align: left;
      }
    }
  }
  &__block-form{
    max-width: 1113px;
    margin: 0 auto;
    padding: 0 0 30px 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    @media (max-width: 1115px) {
      padding-right: 25px;
    }
    @media (max-width: 900px) {
      background: url("/img/bg_gradient.png") no-repeat 10px -750px,
      url("/img/bg_gradient.png") no-repeat -850px calc(100% + 850px);

      padding: 0 25px;
      box-sizing: border-box;
      width: 100%;
    }
    h3{
      font-family: 'Montserrat',sans-serif;
      font-size: 50px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      max-width: 430px;
      width: 42%;
      margin: 30px 0 0 -80px;
      padding-top: 80px;
      padding-left: 80px;
      background: url("/img/plus.png") no-repeat left top;
      background-size: 77px;
      strong{
        font-weight: 800;
        display: block;
      }
      @media (max-width: 1220px) {
        padding-left: 25px;
        margin-left:0 ;
      }
      @media (max-width: 900px) {
        background: url("/img/plus.png") no-repeat right top;
        background-size: 43px;
        padding-top: 43px;
        padding-left: 0;
        margin-top: 20px;
        font-size: 28px;
        max-width: 95%;
        width: 100%;
        strong{
          display: inline;
        }
      }
    }
  }
  &__steps{
    width: 55%;
    max-width: 550px;
    position: relative;
    @media (max-width: 900px) {
      max-width: 100%;
      width: 100%;
      padding-top: 90px;
      padding-bottom: 35px;
    }
    .MuiStepLabel-iconContainer{
      display: none;
    }
    .steps-name{
      position: absolute;
      width: 50%;
      height: 60px;
      top: -53px;
      padding: 0;
      @media (max-width: 900px) {
        top: 47px;
      }
      &:first-child{
        left: 0;
      }
      &:last-child{
        right: 0;
      }
      &>span{
        display: block;
        height: 100%;
        width: 100%;
        position: relative;
        .Mui-active{
          font-family: 'Montserrat',sans-serif;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.75px;
          text-align: center;
          color: #000;
          text-transform: uppercase;
          &:after{
            content: '';
            height: 7px;
            width: 100%;
            display: block;
            border-radius: 3.5px;
            background-color: #ffc600;
            position: absolute;
            bottom: 0;
          }
          @media (max-width: 667px){
            font-size: 13px;
          }
        }
        .Mui-disabled, .Mui-completed{
          font-family: 'Montserrat',sans-serif;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.75px;
          text-align: center;
          color: rgba(0, 0, 0, 0.5);
          text-transform: uppercase;
          &:after{
            background: none;
          }
          @media (max-width: 667px){
            font-size: 13px;
          }
        }
      }
    }
    .MuiStepConnector-line{
      border-radius: 3.5px;
      background-color: #d2d4d7;
      height: 7px;
      border: none;
      @media (max-width: 900px) {
        margin-top: 10px;
      }
    }
  }
  &__contact{
    max-width: 546px;
    padding-left: 10px;
    padding-top: 390px;
    h3{
      font-family: 'Montserrat',sans-serif;
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 6px;
      text-align: left;
      color: #000;
      text-transform: uppercase;
      margin: 0 0 45px;
    }
    .bottom{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 1120px){
        button{
          margin-top: 20px;
        }
      }

    }
    @media (max-width: 1120px){
      padding-right: 15px;
    }
    @media (max-width: 900px){
      padding-top: 75px;
      margin: 0 auto;
      max-width: 500px;
    }
  }
}
.company__box{
  padding-top: 35px;

}
 form .btn.MuiButton-contained{
  border-radius: 5px;
  display: block;
  background-image: linear-gradient(to top, #ff9d00, #ffc600);
  width: 137px;
  height: 48px;
  padding-left: 32px;
  font-family: 'Montserrat',sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: 1.6px;
  text-align: left;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  cursor: pointer;
}
.input-text{
  border-radius: 4px;
  border: solid 1px #d1d1d1;
  background-color: #fff;
  width: 100%;
  height: 50px;
  margin-bottom: 25px;
  padding-left: 15px;
  font-family: 'Nunito Sans',sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  &:before, &:after{
    display: none;
  }
  input{
    font-family: 'Nunito Sans',sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
}
.phone__box.PhoneInput{
  margin-bottom: 20px;
  display: inline-flex;
  width: 100%;
  select.PhoneInputCountrySelect{
    height: 50px;
    width: 100px;
  }
  .PhoneInputCountry{
    width: 100px;
    border-radius: 4px;
    border: solid 1px #d1d1d1;
    background-color: #fff;
    height: 50px;
    padding-left: 15px;
    box-sizing: border-box;
  }
  input{
    border-radius: 4px;
    border: solid 1px #d1d1d1;
    background-color: #fff;
    width: calc(100% - 100px);
    height: 50px;
    padding-left: 15px;
    font-family: 'Nunito Sans',sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    box-sizing: border-box;
  }
  .PhoneInputCountrySelectArrow{
    margin-left: 40px;
  }
}
.select-box{
  width: 100% !important;
  margin: 0 0 20px !important;
  label{
    font-family: 'Nunito Sans',sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #020202;
  }
  .select{
    height: 50px;
    &>div{
      color: #000;
    }
  }
}
.textarea{
  padding: 19px 5px 5px 15px;
  border-radius: 4px;
  border: solid 1px #d1d1d1;
  background-color: #fff;
  width: 100%;
  height: 125px;
  margin: 0 0 20px!important;
}
.checkbox{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  label{
    font-family: 'Nunito Sans',sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    a{
      font-weight: 600;
      color: #ff9d00;
    }
  }
}
.footer__box{
  width: 100%;

  .footer__top{
    width: 100%;
    height: 317px;
    padding: 0 70px;
    background: url("/img/bg_footer.jpg") no-repeat center top;
    background-size: cover;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    @media  (max-width: 900px){
      padding: 35px 20px;
    }

    .static-page &{
      @media (max-width: 667px){
        margin: 0 -15px;
        width: calc(100% + 30px);
      }
    }
  }
  h2{
    color: #fff;
    margin-bottom: 25px;
    font-size: 40px;
    max-width: 1190px;
    font-weight: bold;
    @media  (max-width: 1100px){
      font-size: 35px;
    }
    @media  (max-width: 900px){
      font-size: 20px;
      text-align: center;
    }
  }
  .btn{
    margin: 0 auto;
  }
  .copyrite_box{
    padding: 65px 0 50px;
    img{
      width: 24px;
      height: 24px;
    }
    .MuiGrid-item{
      margin: 0 12px 20px 12px;
    }
    p{
      font-family: 'Nunito Sans',sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #d1d1d1;
      margin: 0;
    }

  }
}
.contact{
  &__box{
    background: url("/img/contact_bg.jpg") no-repeat center top, url("/img/bg_gradient.png")no-repeat  no-repeat calc((100vw - 1115px)/2 - 600px) calc(100% + 850px);
    width: 100%;
    padding: 15vw 0 100px;
    background-size: contain, auto;

    @media (max-width: 1600px){
      padding: 150px 0 100px;
    }
    @media (max-width: 1360px){
      padding: 100px 0 100px;
      background-size: auto;
    }
    @media (max-width: 900px){
     background-position: 75% 67px, calc((100vw - 1115px)/2 - 600px) calc(100% + 850px);
    }
    @media (min-width: 2000px){
      padding: 20vw 0 100px;
    }
    @media (min-width: 2600px){
      padding: 23vw 0 100px;
    }
    @media (min-width: 3300px){
      padding: 25vw 0 100px;
    }
    @media (min-width: 4000px){
      padding: 27vw 0 100px;
    }
  }
  &__content{
    width: 95%;
    max-width: 1113px;
    margin: 0 auto;
    h2{
      color: #fff;
      font-size: 60px;
      margin-bottom: 120px;
      text-align: left;
      @media (max-width: 1120px){
        padding-left: 25px;
      }
      @media (max-width: 900px){
        padding-left: 0;
        text-align: center;
        padding-top: 70px;
      }
    }

    @media (max-width: 900px){
      &>div{
        width: 100%;
      }
    }
  }
  &__list{
    max-width: 400px;
    @media (max-width: 900px){
      max-width: 100%;
    }
  }
  &__help{
    &-box {

      border-radius: 10px;
      box-shadow: 13px 22.5px 38px 0 rgba(10, 17, 33, 0.16);
      background: #fff url("/img/plus.png") no-repeat calc(100% - 40px) 42px;
      background-size: 47px;
      padding: 70px 50px 60px 55px;
      max-width: 515px;
      min-height: 711px;
      box-sizing: border-box;
      @media (max-width: 1120px){
        margin-left: 25px;
      }
      @media (max-width: 900px){
        margin: 0 auto;
        padding: 70px 20px 60px 20px;
        width: 95%;
      }

      h3 {
        font-family: 'Montserrat',sans-serif;
        font-size: 48.5px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        max-width: 250px;
        margin: 0 0 30px;
      }

      p {
        font-family: 'Nunito', sans-serif;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.48;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-bottom: 40px;
      }

      li {
        border-radius: 10px;
        background-color: #e9ecef;
        padding: 15px;
      }
    }
    &-avatar{
      border-radius: 7px;
      background-color: #fff;
      margin: 0 20px 0 0;
      padding: 10px;
      width: 62px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1000px){
        width: 45px;
        height: 45px;
        min-width: 45px !important;
      }
      div{
        width: 100%;
        height: 100%;
        border-radius: 0;
        img{
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    &-text{
      &>span{
        font-family: 'Montserrat',sans-serif;
        font-size: 24px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        @media (max-width: 1000px){
          font-size: 18px;
        }

      }
      p, a{
        font-family: 'Nunito Sans',sans-serif;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-bottom:0 ;
        @media (max-width: 1000px){
          font-size: 16px;
        }
        @media (max-width: 500px){
          font-size: 14px;
        }
      }

    }
  }
}
.congrats{
  width: 100%;
  padding-top: 50px;
  background: url("/img/form_send_bg.png") no-repeat center 100px;
  background-size: contain;
  padding-bottom: 150px;
  @media (max-width: 900px){
    padding-bottom: 50px;
  }
  img{
    margin: 20px auto;
    display: block;

  }
  h3{
    font-family: Montserrat, sans-serif;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    margin:0 auto 40px;
    display: block;
    background: none;
    padding: 0;
  }
  h4{
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin:0 auto 40px;
    text-align: center;
    max-width: 340px;
    display: block;
  }
  p{
    font-family: Nunito Sans, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    max-width: 350px;
    display: block;
    margin: 0 auto;
  }
}
.modal__box{
  padding: 50px 65px !important;
  box-sizing: border-box;
  @media (max-width: 550px){
    padding: 35px 20px !important;
  }
  h2{
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }
  p{
    font-family: Montserrat,sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 3.2px;
    text-align: center;
    color: #000;
  }
  .close{
    position: absolute;
    padding: 0;
    width: 15px;
    min-width: 15px;
    height: 15px;
    right: 24px;
    top: 23px;
  }
  &-buttons{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 15px;
    .modal__link{
      width: 190px;
      height: 137px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      box-shadow: 4px 6.9px 16px 0 rgba(10, 17, 33, 0.16);
      border-style: solid;
      border-width: 1px;
      border-image-source: linear-gradient(to top, #ff9d00, #ffc600);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to top, #ff9d00, #ffc600);
      background-origin: border-box;
      background-clip: content-box, border-box;
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
      @media (max-width: 550px){
        max-width: 45%;
      }
    }
  }
}
.aboutus_article{
  margin: 0 auto;
  padding: 80px 0;
  @media (max-width: 900px){
    padding-top: 200px;
  }
  h3{
    font-family: 'Montserrat',sans-serif;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    @media (max-width: 900px){
      font-size: 24px;
    }
  }
  p{
    font-family: "Nunito Sans", sans-serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    @media (max-width: 900px){
      font-size: 16px;
    }
    &.inthenews_page__header_copy{
      font-family: "Nunito Sans", sans-serif;
      font-size: 18px;
      font-style: italic;
    }
  }
  a{
    color:#ff9d00;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
    &.pdf{
      font-size: 14px;
      float: right;
    }
  }
  li{
    font-family: "Nunito Sans", sans-serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    @media (max-width: 900px){
      font-size: 16px;
    }
  }
  &__video{
    float: left;
    margin-right: 25px;
    @media (max-width: 900px){
      margin: 0;
      float: none;
      iframe{
        max-width: 100%;
      }
    }
  }

  .float{
    float: left;
    margin-right: 25px;
    @media (max-width: 900px){
      margin: 0;
      float: none;
    }
  }
}
#valid{
  display: none;
}